@import "src/styles/variables.scss";

.header {
  width: 100%;
  text-align: center;
  // height: 230px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background: $black2;
  min-height: 74px;

  &__title {
    // margin: 30px 0 20px;
    padding: 25px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #fff;
    position: relative;
  }

  &__dashboard-link {
    text-align: left;
    width: max-content;
    cursor: pointer;
    padding: 5px;
    padding-left: 15px;
    line-height: 1;
  }

  &__dashboard-btn {
    transform: translateY(20%);
    font-size: 20px;
  }

  &__back-btn {
    position: absolute;
    top: 24px;
    left: 20px;
    color: #fff;
    font-size: 30px;
    transform: translate(-25%, -10%);
    cursor: pointer;
  }

  &__balance {
    margin-bottom: 15px;
  }

  &__balance-description {
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #fff;
    margin-bottom: 15px;
  }

  &__balance-container {
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  &__balance-unit {
    font-weight: 500;
    font-size: 20px;
    // line-height: 24px;
    letter-spacing: 0.15px;
    color: #fff;
    opacity: 0.6;
  }

  &__balance-value {
    color: $baseGold;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.18px;
  }

  &__currency-balance {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__currency-balance-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #fff;
    opacity: 0.6;
  }
}
