@import "src/styles/variables.scss";

.contact-us-screen {
  padding: 25px 16px;

  // &__back-btn {
  //   display: flex;
  //   align-items: center;
  //   cursor: pointer;
  // }
}
