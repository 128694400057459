@import "src/styles/variables.scss";

.staking {
  padding: 34px 17px;
  display: flex;
  align-items: center;
  flex-flow: column;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #282a2b;
  }

  &__rates {
    width: 350px;
    max-width: 100%;
  }

  &__periods {
    display: flex;
    justify-content: flex-end;
    color: #282a2b;
    letter-spacing: 0.25px;
    margin: 20px 0 5px;
    gap: 4px;
  }

  &__period {
    display: flex;
    font-size: 10px;
    line-height: 16px;
    align-items: flex-end;
    width: 63px;
    justify-content: center;

    @media screen and (max-width: 375px) {
      width: 60px;
    }

    @media screen and (max-width: 360px) {
      width: 55px;
    }

    @media screen and (max-width: 340px) {
      width: 50px;
    }
  }

  &__period-value {
    font-size: 14px;
    line-height: 20px;
  }

  &__currency-rates {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__rate-currency {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #282a2b;
    gap: 7px;
    white-space: nowrap;
  }

  &__rate-currency-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  &__rate-items {
    display: flex;
    gap: 4px;
  }

  &__rate-item {
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 63px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #282a2b;
    cursor: pointer;

    @media screen and (max-width: 375px) {
      width: 60px;
    }

    @media screen and (max-width: 360px) {
      width: 55px;
    }

    @media screen and (max-width: 340px) {
      width: 50px;
    }
  }

  &__rates-hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #282a2b;
    margin-top: 15px;
    margin-bottom: 64px;

    a {
      color: $baseViolet;
    }
  }

  &__description {
    text-align: center;
    margin-bottom: 50px;
  }

  &__available {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.4px;
    color: #4b4b4c;
    opacity: 0.6;
    width: 320px;
    margin-top: 15px;
    margin-bottom: 3px;
  }

  &__currencies {
  }

  &__currency-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    height: 56px;
    width: 340px;
    max-width: 100%;
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__currency-item-icon-name {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__currency-item-balance {
    letter-spacing: 0.4px;
    color: #4b4b4c;
    opacity: 0.6;
    font-size: 12px;
  }
}
