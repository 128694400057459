$maxContentWidth: 480px;
$componentWidth: 300px;
$scrollWidth: 10px;

$black1: #20202c;
$black2: #21212a;
$black3: #4b4b4c;
$baseGold: #ebbb71;
$baseGray: #f5f5f8;
$baseViolet: #6200ee;

$textGray: #979797;
$textDarkGray: #4b4b4c;

$primary: $baseGold;
$secondary: $baseViolet;
$warning: #ff6565;

$backgroundWhite: white;
$backgroundGray: $baseGray;
$backgroundDark: $black1;

$font: "Roboto", sans-serif;
$round: 8px;
$cardShadow: 4px 4px 10px rgba(0, 0, 0, 0.12);

@mixin container {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin: 0 auto;
  width: $maxContentWidth;
  max-width: 100%;
  min-height: 100vh;
  @media screen and (max-width: $maxContentWidth) {
    min-height: -webkit-fill-available;
  }
}
