@import "src/styles/variables.scss";

.currency-details {
  padding: 25px 16px;
  &__card {
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 15px 10px;
    margin-bottom: 18px;
  }

  &__filters {
    display: flex;
    gap: 10px;
  }

  &__column-title {
    margin: 8px 0 5px;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    color: #4b4b4c;
    opacity: 0.4;
  }

  &__tx {
    margin-bottom: 5px;
    padding: 0 5px;
  }

  &__filter-btn {
    height: 24px;
    background: rgba(33, 33, 33, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 3px 15px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.38);
    text-transform: capitalize;
    line-height: 1.2;

    &_active {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__btn {
    width: 155px;

    &_long {
      width: 270px;
    }
  }

  &__empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 23px;
    height: 30vh;
  }

  &__empty-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #4b4b4c;
    text-align: center;
    margin-top: 15px;
  }

  &__loader {
    margin: 20px;
  }
}
