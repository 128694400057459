@import "src/styles/variables.scss";

.unauth-layout {
  @include container();

  background: $black1;
  color: #fff;
  padding: 0 30px;
  position: relative;

  &_android {
    min-height: 100vh;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $baseGold;
  }

  &__back {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__back-icon {
    font-size: 24px;
  }

  &__logo {
    margin-top: 5vh;
    margin-bottom: 25px;
    min-height: 250px;
    // width: 360px;
    // object-fit: contain;
    // max-width: 100%;
  }

  &__title {
    color: $baseGold;
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 35px;
    text-align: center;

    &_without-img {
      margin-top: 25vh;
    }
  }
}
