@import "src/styles/variables.scss";

.ambassador {
  padding: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000;

  &__title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin: 25px auto;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    margin-bottom: 14px;
  }

  &__item-text {
    font-size: 16px;
    display: flex;
    align-items: center;

    &_grey {
      color: #979797;
    }
  }

  &__item-action {
    min-width: 70px;
    width: 70px;
    height: 56px;
    border-radius: 8px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    background: $baseGold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    pointer-events: none;
    text-transform: capitalize;

    &_disabled {
      opacity: 0.4;
      cursor: pointer;
      pointer-events: all;
    }
  }
}
