@import "src/styles/variables.scss";

.purchase {
  padding: 25px 16px;
  &__currencies {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 30px;
  }

  &__currency-btn {
    width: 140px;
    border-radius: 29px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
  }

  &__currency-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__currency-rate {
    margin-top: 6px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #4b4b4c;
  }

  &__amount {
    margin: 22px auto;
    background: rgba(40, 42, 43, 0.12);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;

    input {
      color: #282a2b;
    }

    input::placeholder {
      color: #282a2b;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 305px;
    margin: 0 auto 27px;
    max-width: 100%;
  }

  &__total-amount {
    font-weight: 500;
    font-size: 20px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #4b4b4c;
  }

  &__pay-pal {
    height: 56px;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #f5f5f8;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
}
