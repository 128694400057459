@import "src/styles/variables.scss";

.wallet {
  padding: 25px 16px;
  &__currencies-balances {
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  &__buy-btn {
    height: 48px !important;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14),
      0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
    border-radius: 50px !important;
    margin: 32px auto;
  }

  &__btw-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__data-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $black3;
  }

  &__column-name {
    font-size: 10px;
    line-height: 16px;
    color: $black3;
    opacity: 0.4;
  }

  &__txs-link {
    margin-top: 20px;
    font-size: 10px;
    line-height: 16px;
    color: $baseViolet;
  }
}
