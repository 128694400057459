@import "src/styles/variables.scss";

.confirm-tx-password {
  padding: 25px 16px;
  display: flex;
  flex-flow: column;
  align-items: center;

  &__forgot {
    color: $baseViolet;
    cursor: pointer;
    align-self: flex-start;
  }

  &__logo {
    height: 200px;
  }

  &__btn {
    margin-top: 20px;
  }

  &__input {
    position: relative;

    .input-component__icon {
      color: #282a2b;
    }

    &:focus-within {
      .input-component__placeholder {
        color: $black3;
      }
      .input-component__icon {
        color: #282a2b;
      }
    }

    .input-component__placeholder {
      color: #282a2b;
    }

    input {
      background: rgba(40, 42, 43, 0.12);
      color: #282a2b;

      &:not(:placeholder-shown) {
        & + .input-component__placeholder {
          color: $black3;
        }
      }
    }
  }

  &__back {
    font-weight: 500;
    cursor: pointer;
  }
}
