@import "src/styles/variables.scss";

.transaction-info {
  &__content {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  // &__date {
  //   margin-top: 10px;
  // }
  &__title,
  &__date {
    font-weight: 400;
    font-size: 12px;
    // line-height: 32px;
    color: #4c4c4c;
  }

  &__title {
    // padding-bottom: 10px;
    // border-bottom: 1px solid rgba(203, 203, 203, 0.5);
    text-decoration: underline;
  }

  &__amount-usd {
    // padding-bottom: 10px;
    border-bottom: 1px solid rgba(203, 203, 203, 0.5);
    margin-bottom: 20px;
  }
  &__info {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4c4c4c;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    // white-space: nowrap;
  }
  &__type {
    text-transform: capitalize;
    max-width: 120px;
    word-break: break-word;
  }
  &__amount-usd {
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    text-align: right;
    color: #c4c4c4;
  }
  &__close {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #20202c;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;
  }
}
