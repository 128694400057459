@import "src/styles/variables.scss";

.ambassador-program {
  &__content {
    padding: 20px;
  }

  &__logo {
    display: block;
    margin: 0 auto;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.18px;
    color: #20202c;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4c4c4c;
    margin-bottom: 40px;
  }

  &__btn {
    width: 252px;
    margin: 40px auto;
  }
}
