@import "src/styles/variables.scss";

.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black3;
  cursor: pointer;
  height: 40px;
  margin-bottom: 8px;
  &__icon-type-wrapper {
    display: flex;
    align-items: center;
    gap: 13px;
    flex: 1.5;
  }
  // &__icon {
  //   width: 24px;
  //   height: 24px;
  //   svg {
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  &__type {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &__date {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    opacity: 0.6;
    flex: 1;
    text-align: center;
  }
  &__amounts {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
    position: relative;
  }
  &__amount {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.1px;
    color: #4b4b4c;
  }
  &__amount-usd {
    position: absolute;
    right: 0;
    bottom: -14px;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    color: #4b4b4c;
    opacity: 0.4;
  }
}
