@import "src/styles/variables";

.button {
  font-family: "Roboto", sans-serif;
  width: $componentWidth;
  height: 36px;
  font-size: 14px;
  letter-spacing: 1.25px;
  border: none;
  color: $black1;
  border-radius: 4px;
  background: $baseGold;
  font-weight: 500;
  max-width: 100%;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &_outline {
    background: transparent;
    border: 1px solid $baseGold;
  }
}
