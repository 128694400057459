@import "src/styles/variables.scss";

.landing-about-us {
  padding: 60px 23px 30px;
  background: #222b32;
  color: $baseGold;
  margin-top: 30px;

  &__title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.18px;
  }

  &__swiper {
    width: 100%;
  }

  .swiper-slide {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    position: relative;
    margin-top: 20px;
    height: calc(700px - 5vw);

    @media screen and (max-width: 340px) {
      height: 750px;
    }
    @media screen and (max-width: 320px) {
      height: 800px;
    }
  }

  &__slide-img-wrapper {
    max-width: 100%;
    margin: 40px auto;
    text-align: center;
  }

  &__slide-img {
    object-fit: contain;
    border-radius: 50%;
    width: 160px;
    height: 160px;
  }

  &__slide-title,
  &__slide-subtitle {
    font-weight: 500;
    font-size: 20px;
  }

  &__slide-subtitle {
    font-size: 16px;
    margin-bottom: 40px;
  }

  &__slide-text {
    color: #fff;
  }

  .swiper-pagination-bullet {
    background: rgba(151, 151, 151, 1);
    opacity: 0.4;
    width: 11px;
    height: 11px;
  }

  .swiper-pagination-bullet-active {
    background: #979797;
    opacity: 1;
  }
}
