@import "src/styles/variables.scss";

.privacy {
  padding: 25px 16px;

  &__title {
    text-align: center;
    font-weight: 700;
  }

  br {
    content: " ";
    display: block;
    margin: 10px 0;
    line-height: 1.2;
  }
}
