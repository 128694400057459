@import "src/styles/variables.scss";

.landing-footer {
  padding: 30px 25px;
  background: #20202c;
  color: $baseGold;
  position: relative;

  // &__links {
  //   display: flex;
  //   justify-content: space-between;
  //   width: 80%;
  //   margin: 40px auto;
  // }

  // &__link {
  //   font-weight: 400;
  //   font-size: 10px;
  //   line-height: 16px;
  //   color: #fcd507;
  //   width: 46px;
  // }

  &__block {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 25px auto;
  }

  &__block-title {
    font-size: 16px;
    // line-height: 24px;
    letter-spacing: 0.15px;
    flex: 1;
    // width: 40%;
    text-align: right;
  }

  &__block-links {
    display: flex;
    flex-flow: column;
    flex: 1.5;
  }

  &__block-link {
    font-size: 14px;
    // line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 8px;
    color: $baseGold;
  }

  &__btn {
    margin: 50px auto;
  }

  &__user-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  &__link {
    color: $baseGold;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    border-bottom: 1px solid $baseGold;
  }
}
