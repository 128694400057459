@import "src/styles/variables.scss";

.staking-currency {
  padding: 34px 17px;
  display: flex;
  align-items: center;
  flex-flow: column;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    gap: 5px;
  }

  &__amount,
  &__referral-code {
    position: relative;

    &:focus-within {
      .input-component__placeholder {
        color: $black3;
      }
    }

    .input-component__placeholder {
      color: #282a2b;
    }

    input {
      background: rgba(40, 42, 43, 0.12);
      color: #282a2b;

      &:not(:placeholder-shown) {
        & + .input-component__placeholder {
          color: $black3;
        }
      }
    }
  }

  &__amount {
    margin-bottom: 34px;
  }

  &__amount-input {
    margin-bottom: 8px;
  }

  &__possible-parts {
    display: flex;
    align-items: center;
    gap: 6px;

    & > div {
      background: $baseGold;
      border-radius: 14px;
      width: 40px;
      cursor: pointer;
      flex: 1;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.4px;
      color: #4b4b4c;
      opacity: 0.8;
    }
  }

  &__amount-available {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #4b4b4c;
    opacity: 0.8;
    margin-top: 10px;
  }

  &__rates-hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #282a2b;
    margin-top: 15px;
    margin-bottom: 30px;

    a {
      color: $baseViolet;
    }
  }

  &__periods {
    display: flex;
    align-items: center;
    gap: 6px;
    // margin-bottom: 25px;
  }

  &__period-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &__period {
    background: $baseGold;
    border-radius: 8px;
    width: 68px;
    height: 56px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    letter-spacing: 0.25px;
    color: #282a2b;
    font-size: 10px;
  }

  &__period-value {
    font-size: 14px;
  }

  &__period-percent {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #282a2b;
    margin-top: 5px;
  }

  &__interest-currency {
    margin-bottom: 26px;
  }

  &__interest-currency-btn {
    // background: #ebbb71;
    border-radius: 29px;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    text-transform: capitalize;
    font-weight: 400;
  }

  &__interest-currency-btn-name-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__interest-currency-return {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #4b4b4c;
  }

  &__interest-currency-price {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #4b4b4c;
    text-transform: capitalize;
  }

  &__referral-code {
    margin-top: 20px;
  }

  &__next {
    width: 254px;
  }

  &__expected-return-loader {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 200px;
    margin-top: 5px;
  }
}
