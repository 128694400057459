@import "src/styles/variables.scss";

.landing-contact-us {
  padding: 30px 20px;

  &__title {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.18px;
    color: #252b42;
    margin-bottom: 30px;
  }

  &__card {
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 30px 24px;
    margin: 0 auto 0;
  }

  &__input,
  &__textarea {
    background: #f5f5f5;
    border: 1px solid #e8e8e8;
    border-radius: 39px;
    width: 100%;
    height: 55px;
    padding: 20px;
    margin-bottom: 24px;

    &::placeholder {
      color: #18171d;
    }
  }

  &__textarea {
    max-width: 100%;
    resize: vertical;
    min-height: 180px;
    border-radius: 20px;
  }
}
