@import "src/styles/variables.scss";

.reset-password {
  &__back {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &__back-icon {
    font-size: 24px;
  }
  &__text {
    width: 270px;
    margin: 15px auto 22px;
  }
  &__phone {
    color: $baseGold;
  }
  &__btn {
    margin-top: 8px;
  }
}
