@import "src/styles/variables.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &_full-page {
    height: 90vh;
  }
}
