@import "src/styles/variables.scss";

.login-register-link {
  color: #fff;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 15px 0;
  text-align: center;

  &__link {
    color: $baseGold;
  }
}
