@import "src/styles/variables.scss";

.staking-details {
  padding: 34px;
  display: flex;
  align-items: center;
  flex-flow: column;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 15px;
  }

  &__card {
    padding: 18px 30px 8px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-bottom: 30px;
  }

  &__card-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #4b4b4c;

    &_with-border-top {
      padding: 10px 0;
      border-top: 1px solid rgba(203, 203, 203, 0.5);
      margin-bottom: 0;
    }
  }

  &__ambassador-name {
    font-size: 10px;
    line-height: 16px;
    color: #282a2b;
    opacity: 0.8;
  }

  &__card-item-label {
    opacity: 0.8;
  }

  &__card-item-value {
    letter-spacing: 0.1px;
    color: #20202c;
  }

  &__allow {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 0 10px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  &__allow-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #282a2b;

    a {
      color: $baseViolet;
    }
  }

  &__confirm {
    margin-top: 10px;
  }
}
