@import "src/styles/variables.scss";

.landing-contacts {
  width: 340px;
  max-width: 90%;
  margin: 30px auto;
  display: flex;

  &__item {
    width: 100px;
    height: 65px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  &__icon {
    width: 36px;
    height: 36px;
    color: #f2c94c;
  }

  &__text {
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #374754;
  }
}
