@import-normalize;
@import "./fonts.scss";
@import "./variables.scss";

body,
html {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  // min-height: 90vh;
  background: #e5e5e5;
  @media screen and (max-width: $maxContentWidth) {
    min-height: -webkit-fill-available;
  }
  // height: calc(100vh - env(safe-area-inset-bottom));
}

* {
  scrollbar-color: darkgrey rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: $scrollWidth;
  height: $scrollWidth;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px;
}

#root {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

html *,
html :after,
html :before {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app {
  width: $maxContentWidth;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
// .app {
//   display: flex;
//   flex-flow: column;
//   // height: calc(100vh - 55px);
//   min-height: 100vh;
// }

@-moz-keyframes rotateClockwise {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateClockwise {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotateClockwise {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.rotateClockwise {
  webkit-animation: rotateClockwise 2s linear infinite;
  -moz-animation: rotateClockwise 2s linear infinite;
  animation: rotateClockwise 2s linear infinite;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 14px;
  font-family: Arial;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

.input-error {
  margin: 3px 5px;
  width: $componentWidth;
  font-size: 14px;
  // line-height: 20px;
  letter-spacing: 0.25px;
  color: #ff6565;
  text-align: left;
  // position: absolute;
}
