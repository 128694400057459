@import "src/styles/variables.scss";

.staking-summary {
  padding: 34px;
  display: flex;
  align-items: center;
  flex-flow: column;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 15px;
  }

  &__card {
    padding: 8px 30px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-bottom: 30px;
  }

  &__card-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }

  &__card-item-label {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4b4b4c;
    opacity: 0.8;
  }

  &__card-item-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.1px;
    color: #20202c;
  }

  &__table-titles {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
  }

  &__table-title {
    text-align: center;
    flex: 1;

    &:first-child {
      flex: 2;
    }
  }

  &__stakes {
    font-size: 14px;
    width: 100%;
  }

  &__stake-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__amount {
    display: flex;
    align-items: center;
    // justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
    // flex: 2;
    padding-left: 12%;
    width: 45%;
  }

  &__interest,
  &__remaining {
    // flex: 1;
    text-align: center;
    width: 25%;
    font-size: 14px;
  }

  &__remaining {
    span {
      font-size: 10px;
    }
  }

  &__btn {
    margin: 30px 0;
    border: 1px solid #ebbb71;
    border-radius: 4px;
    background: transparent;
    width: 253px;
  }
}
