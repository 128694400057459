@import "src/styles/variables";

.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  color: $baseGold;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;

  &__refresh-icon {
    color: $baseGold;
  }
}
