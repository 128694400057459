@import "src/styles/variables.scss";

.invite-earn {
  &__register-layout {
    background: linear-gradient(
        180deg,
        #8f4c7d 0%,
        rgba(255, 219, 165, 0.56) 100%
      ),
      #ffffff;

    & > .landing-content-block {
      margin-top: 0;
      padding: 50px 0;
    }
  }

  &__card {
    background: #fff;
    padding: 40px 15px;
    border-radius: 20px;
    // width: 90%;
  }
}
