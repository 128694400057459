@import "src/styles/variables.scss";

.tab-bar {
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: $black3;
  height: 56px;
  background: #fff;
  width: 100%;
  z-index: 1;
  // position: fixed;
  // bottom: 0;

  &__link {
    display: flex;
    align-items: center;
    flex-flow: column;
    color: $black3;
    flex: 1;

    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    opacity: 0.4;
  }

  &__link-icon {
    width: 20px;
    height: 20px;
  }
}
