@import "src/styles/variables.scss";

.page-not-found {
  @include container();

  justify-content: center;
  font-size: 2rem;
  font-weight: 500;
  background: $black1;
  color: $baseGold;

  & > div:first-child {
    font-size: 6rem;
    margin-bottom: 20px;
  }

  button{
    margin-top: 30px;
  }
}
