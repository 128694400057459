@import "src/styles/variables.scss";

.contact-us-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: $black3;
  background: #e5e5e5;
  width: 100%;;

  &__link {
    color: $baseViolet;
  }
}
