@import "src/styles/variables.scss";

.landing-header {
  padding: 50px 33px 70px;
  background: #20202c;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #fff;
  line-height: 24px;
  position: relative;

  &__logo {
    width: 246px;
    height: 38px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
  }
}
