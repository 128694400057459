// create mixin for bind option to css value
.space {
  display: flex;
  &--direction {
    &-horizontal {
      flex-direction: row;
    }
    &-vertical {
      flex-direction: column;
    }
  }
  &--justify {
    &-start {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: end;
    }
    &-space-between {
      justify-content: space-between;
    }
    &-space-around {
      justify-content: space-around;
    }
    &-stretch {
      justify-content: stretch;
    }
  }
  &--align {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }
  &--inline {
    display: flex;
  }
}
