@import "src/styles/variables.scss";

.auth-layout {
  @include container();

  // padding: 0 30px;

  &__content {
    width: 100%;
    display: flex;
    flex-flow: column;
    // justify-content: center;
    // align-items: center;
    flex: 1;
    background: $baseGray;
    padding: 25px 16px;
  }
}
