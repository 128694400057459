@import "src/styles/variables.scss";

.finished-stakes {
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 15px;
  }

  &__table-titles {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
  }

  &__table-title {
    text-align: center;
    white-space: nowrap;
    flex: 1;

    &:first-child {
      flex: 3;
    }
  }

  &__stakes {
    font-size: 14px;
    width: 100%;
  }

  &__stake-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__amount {
    display: flex;
    align-items: center;
    // justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
    // flex: 2;
    padding-left: 12%;
    width: 45%;
  }

  &__finished-date {
    text-align: right;
    width: 30%;
    font-size: 14px;
  }
}
