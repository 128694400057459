@import "src/styles/variables.scss";

.setup-password {
  &__register-btn {
    margin-top: 30px;
  }

  &__description {
    width: 300px;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
  }
}
