@import "src/styles/variables";

.landing-socials {
  display: flex;
  justify-content: center;
  gap: 24px;

  &__item {
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: $baseGold;
    margin: 4px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
