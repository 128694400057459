@import "src/styles/variables.scss";

@mixin activePlaceHolder {
  transform: translateY(8px);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba($baseGray, 0.6);
}

.input-component {
  // height: 54px;
  width: $componentWidth;
  border-radius: 4px 4px 0px 0px;
  max-width: 100%;
  position: relative;
  margin-bottom: 20px;
  opacity: 0.87;
  overflow: hidden;

  &:focus-within {
    opacity: 1;
    .input-component__icon {
      color: #fff;
    }
    .input-component__placeholder {
      @include activePlaceHolder();
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 15px;
    pointer-events: none;
    transform: translateY(65%);
    transition: all 0.2s ease-in-out;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.87);
  }

  input {
    outline: none;
    width: 100%;
    // height: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 4px 4px 0px 0px;
    padding: 15px;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;

    // &::placeholder {
    //   font-size: 16px;
    //   line-height: 24px;
    //   letter-spacing: 0.15px;
    //   color: rgba(255, 255, 255, 0.87);
    //   opacity: 1;
    // }

    &:focus {
      border-bottom: 2px solid #e3bd7b;
    }

    &:focus,
    &:valid {
      opacity: 1;
      padding-bottom: 0;
    }

    &:not(:placeholder-shown) {
      & + .input-component__placeholder {
        @include activePlaceHolder();
      }
    }

    &[type="password"] {
      letter-spacing: 2px;
    }
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 27px;
    right: 5px;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  &__unit {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
