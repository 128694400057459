@import "src/styles/variables.scss";

.withdraw-currency {
  padding: 25px 16px;

  &__card {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 30px 15px;
    width: 340px;
    max-width: 100%;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 5px;
  }

  &__qr-reader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    box-shadow: 0px 0px 0 500px #000 inset;
    overflow: hidden;
  }

  &__qr-reader-frame {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    z-index: 2;
    border: 80px solid rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 0px 5px $baseGold inset;
    width: 100%;
    height: 100%;
  }

  &__qr-reader-close {
    position: fixed;
    right: 30px;
    top: 30px;
    color: white;
    z-index: 2;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  &__address,
  &__amount {
    position: relative;

    &:focus-within {
      .input-component__placeholder {
        color: $black3;
      }
    }

    .input-component__placeholder {
      color: #282a2b;
    }

    input {
      background: rgba(40, 42, 43, 0.12);
      color: #282a2b;

      &:not(:placeholder-shown) {
        & + .input-component__placeholder {
          color: $black3;
        }
      }
    }
  }

  &__address {
    display: flex;
    input {
      width: 264px;
    }
  }

  &__amount {
    margin-bottom: 28px;
  }

  &__amount-input {
    width: 100%;
    margin-bottom: 8px;
  }

  &__amount-info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #4b4b4c;
    opacity: 0.8;
  }

  &__possible-parts {
    display: flex;
    align-items: center;
    gap: 4px;

    & > div {
      background: rgba($baseGold, 0.4);
      border-radius: 14px;
      width: 40px;
      text-align: center;
      cursor: pointer;
    }
  }

  &__scan {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 27px;
    transform: translate(0, -50%);
    color: $baseGold;
    cursor: pointer;
  }

  &__info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 16px auto;
  }

  &__info-value {
    font-weight: 500;
    font-size: 20px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #4b4b4c;
  }

  &__confirm {
    margin-top: 24px;
  }
}
