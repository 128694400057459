@import "src/styles/variables.scss";

.switch-language {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  user-select: none;
  color: #fff;
  z-index: 1;
}
