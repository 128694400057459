@import "src/styles/variables.scss";
.transactions {
    padding: 25px 16px;
    &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
    }
    &__btn {
        width: 155px;
    }
    
}