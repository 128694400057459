@import "src/styles/variables.scss";

.checkbox {
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
  &_checked {
    fill: $baseViolet;
  }

  &_unchecked {
    fill: rgba(0, 0, 0, 0.6);
  }
}
