@import "src/styles/variables.scss";

.transfer-tbean {
  padding: 25px 0;
  width: 342px;
  max-width: 100%;
  margin: 0 auto;

  &__currency-icon {
    width: 32px;
    height: 32px;
  }

  &__account-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  &__account-info {
    background: #fff;
    width: 298px;
    max-width: 85%;
    height: 56px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 22px;
  }

  &__account-info-balance {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #4b4b4c;
    opacity: 0.6;
  }

  &__swap-icon {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
    color: $baseGold;
    margin-left: 15px;
    cursor: pointer;
  }

  &__possible-parts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin-top: 5px;
    margin-bottom: 40px;

    & > div {
      background: rgba($baseGold, 0.4);
      color: rgba($textDarkGray, 0.8);
      border-radius: 14px;
      font-size: 12px;
      width: 40px;
      height: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__btn {
    width: 250px;
    margin: 25px auto;
  }
}
