@import "src/styles/variables.scss";

.exchange {
  padding: 25px 16px;

  &__btn {
    width: 90%;
    margin: 0 auto;
  }

  &__currencies {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    // gap: 20px;
    width: 100%;
    margin-top: 30px;
  }

  &__currency-btn {
    width: 135px;
    border-radius: 29px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
  }

  &__currency-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__currency-rate {
    margin-top: 6px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #4b4b4c;
  }

  &__arrow-icon {
    width: 20px;
    height: 18px;
    color: $baseGold;
    margin-top: 9px;
  }

  &__possible-parts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin-top: 5px;

    & > div {
      background: rgba($baseGold, 0.4);
      color: rgba($textDarkGray, 0.8);
      border-radius: 14px;
      font-size: 12px;
      width: 40px;
      height: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
