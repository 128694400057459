@import "src/styles/variables.scss";

.logged-landing {
  background: #f5f5f8;

  &__btn {
    width: 254px;
    margin: 20px auto 50px;
  }

  &__card {
    // padding-top: 100px;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  &__card-img {
    display: block;
    margin: -60px auto 10px;
    // width: 180px;
    // height: 180px;
  }

  &__card-title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.18px;
    color: #20202c;
    margin-bottom: 15px;
  }

  &__card-btn {
    margin: 28px auto 0;
  }
}
