@import "src/styles/variables.scss";

.setup-password {
  &__register-btn {
    margin-top: 30px;
  }

  &__checklist {
    margin-bottom: 10px !important;
    // font-size: 14px;
  }
}
