@import "src/styles/variables.scss";

.transactions-card {
  background: #fff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 16px;
  &__header {
    display: flex;
    align-items: center;
    gap: 60px;
    justify-content: flex-start;
  }
  &__date {
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex: 1;
  }
  &__count {
    text-align: right;
    font-size: 32px;
    display: flex;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
  }

  &__total-amount {
    text-align: right;
  }
  &__tx {
    margin-bottom: 16px;
    &:first-child {
      margin-top: 15px;
    }
  }
  &__row{
    display: flex;
  }

  &__footer{
    justify-content: space-evenly;
    font-weight: 500;
  }
  
}
