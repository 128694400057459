@import "src/styles/variables.scss";

.kyc {
  &__status-card {
    background: #ffffff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 20px;
  }

  &__status {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    // margin-bottom: 20px;
  }

  &__title,
  &__instructions-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin: 40px 0 24px;

    &:first-child {
      margin-top: 15px;
    }
  }

  &__dropzone {
    width: 300px;
    height: 240px;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;

    &_empty {
      width: 300px;
      height: 240px;
      background: #fff;
      border: 1px solid #dadada;
      box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      gap: 25px;
    }

    // img {
    //   max-width: 100%;
    //   height: 100%;
    //   // object-fit: contain;
    //   border-radius: 8px;
    // }
  }

  &__image {
    width: 300px;
    height: 240px;
    margin: 0 auto;
    border-radius: 8px;
  }

  &__change-img {
    width: 250px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__instructions {
    width: 300px;
    margin: 20px auto 10px;
  }

  &__text {
    margin-top: 20px;
  }

  &__text,
  &__instructions-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
  }

  &__instructions-title {
    margin-bottom: 0;
    text-align: left;
  }

  &__submit {
    margin: 10px auto;
  }
}
