@import "src/styles/variables.scss";

.header-links {
  display: flex;

  &__link {
    display: flex;
    justify-content: center;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #e3bd7b;
    height: 50px;
    align-items: center;
  }
}
