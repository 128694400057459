@import "src/styles/variables.scss";

.withdraw-success {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 240px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #4c4c4c;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #808080;
    margin: 36px 0 48px;
  }

  &__close {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #20202c;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
