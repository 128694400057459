@import "src/styles/variables.scss";

.landing-content-block {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 40px auto;
  width: 322px;
  max-width: 90%;

  &__image {
    // max-width: 120px;
    // max-height: 135px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #252b42;
    margin: 20px 0;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #374754;
    text-align: center;
  }

  &__read-more,
  &__link {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba($black3, 0.6);
    margin-top: 10px;
    margin-right: auto;
  }

  &__link {
    font-size: 16px;
    letter-spacing: 0.15px;
    color: $baseViolet;
  }

  &__btn {
    width: 254px;
    margin-top: 25px;
  }
}
