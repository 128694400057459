@import "src/styles/variables.scss";

.agreement {
  padding: 25px 16px;

  &__title {
    text-align: center;
    font-weight: 700;
  }

  &__list-item {
    padding-left: 20px;
    margin-bottom: 12px;
  }

  &__list-sub-item {
    padding-left: 40px;
    margin-bottom: 12px;
  }

  br {
    content: " ";
    display: block;
    margin: 10px 0;
    line-height: 1.2;
  }
}
