@import "src/styles/variables.scss";

.confirm-code {
  color: #fff;

  // input:disabled {
  //   background: red !important;
  // }

  // &_without-img {
  //   div:first-child {
  //     margin-top: 25vh;
  //   }
  // }

  &__input {
    margin-top: 60px;
  }

  &__text,
  &__resend-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    width: $componentWidth;
  }

  &__text {
    margin: 45px 0 30px;
  }

  &__phone {
    color: $baseGold;
  }

  &__resend-btn {
    margin-bottom: 20px;
  }

  &__change-number {
    width: $componentWidth;
    margin-bottom: 15px;
  }

  &__change-number-link {
    color: $baseGold;
  }
}
