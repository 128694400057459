@import "src/styles/variables.scss";

.landing {
  background: #f5f5f8;

  &__btn {
    width: 236px;
    height: 48px;
    border-radius: 35px;
    margin: 20px auto;

    &_sign-up-top {
      background: linear-gradient(180deg, #e6bf7b 0%, #d5a249 100%);
      margin: -24px auto 15px;
      position: relative;
    }
  }

  &__login {
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #20202c;

    a {
      color: $baseViolet;
    }
  }

  &__card {
    background: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 342px;
    max-width: 95%;
    margin: 30px auto 20px;
    padding: 20px 20px 30px;
  }

  &__card-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    color: #252b42;
    margin: 0 35px 20px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #374754;
  }

  &__markets-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 30px 0;

    img {
      width: 105px;
    }
  }

  &__video-container {
    display: flex;
    justify-content: center;
  }

  &__invite-earn-layout {
    background: rgba($baseGold, 0.4);

    & > .landing-content-block {
      margin-top: 0;
      padding: 50px 0;
    }
  }
}
