@import "src/styles/variables.scss";

.currencies-list {
  &__currency-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 254px;
    background: #ffffff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 24px auto;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__icon {
    flex: 0.85;
    text-align: right;
  }
  
  &__title {
    flex: 1;
    text-align: left;
    text-transform: capitalize;
  }
}
