@import "src/styles/variables.scss";

.login {
  &__phone-input {
    margin: 20px 0;
  }

  &__forgot-password {
    width: $componentWidth;
    // max-width: 100%;
    margin: 5px 0 15px;
    padding-left: 10px;
    color: $baseGold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;

    & > div {
      cursor: pointer;
      width: max-content;
    }
  }

  &__login-btn {
    margin-top: 15vh;
  }
}
