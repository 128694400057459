@import "src/styles/variables.scss";

.deposit-currency {
  padding: 25px 16px;
  &__card {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 30px 20px;
    width: 340px;
    max-width: 100%;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4b4b4c;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    gap: 5px;
  }

  &__qr-code {
    margin: 0 auto;
    text-align: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $baseViolet;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 24px 0;
  }

  &__copy-address {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  &__copy-icon {
    width: 20px;
    height: 20px;
  }

  &__save-qr {
    cursor: pointer;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #20202c;
    margin: 24px 0;
  }

  &__warning {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 25px;
    padding: 0 10px;
  }

  &__warning-icon {
    min-width: 35px;
  }

  &__warning-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fc0f0f;
  }

  &__close {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #20202c;
    margin-top: 30px;
    cursor: pointer;
  }
}
