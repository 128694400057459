@import "src/styles/variables.scss";

.about-ambassador {
  br {
    content: " ";
    display: block;
    margin: 10px 0;
    line-height: 1.2;
  }
}
